import { ReactElement } from 'react';
import CTA from './Cta';

type HoursOfOperationProps = {
  locationHours: Record<string, { open: string; close: string }>;
  insuranceSlug?: string | undefined;
};

const allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function HoursOfOperation({
  locationHours,
  insuranceSlug,
}: HoursOfOperationProps): ReactElement {
  return (
    <div className="flex flex-col h-full bg-muted justify-center space-y-3 py-3">
      <div className="py-10 md:py-0 justify-center text-center text-primary-actual space-y-1 px-12 md:px-16">
        <h3 className="text-3xl mb-6">Hours of Operation</h3>
        <div>
          {allDays.map((day) => {
            const hours = locationHours[day];
            return (
              <div key={day} className="flex justify-between text-lg">
                <span className="capitalize">{day}</span>
                {hours && hours.open && hours.close ? `${hours.open} - ${hours.close}` : 'Closed'}
              </div>
            );
          })}
        </div>
        <div className="max-w-cta">
          {insuranceSlug ? (
            <CTA
              {...{
                title: 'accepted-insurances',
                text: 'Accepted Insurances',
                slug: insuranceSlug,
                linkToAsset: undefined,
                colorScheme: 'OutlinedTeal',
                openInNewTab: false,
              }}
              classNames="button-text bg-white py-0 mt-6"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
